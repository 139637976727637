<template>
    <div class="compare-list position-fixed w-100" :style="isOpen ? 'display: block' : 'display: none'">
        <div class="container">
            <div class="compare-list-form">
                <form>
                    <div class="row gx-5">
                        <div class="col-md-3" v-for="(data, index) in data" :key="index">
                            <div class="compare-item position-relative">
                                <!-- <div class="close_item position-absolute"><span class="icon-close_cross" @click="removeCompare(index)"></span>
                                </div> -->
                                
                                <div class="compare-item-block">
                                     <div class="row align-items-center">
                                         <div class="col-lg-5">
                                    <div class="compare-item__logo">
                                    <img :src="`${domain}${url}/img/${data.image}`" class="img-fluid">
                                </div>
                                </div>
                                <div class="col-lg-7">
                                <div class="compare-item__name">
                                    <p>{{ data.policy_name }}</p>
                                </div>    
                                </div>
                                </div>
                            </div>
                            
                            </div>
                        </div>
                        <div class="col-md-3" v-if="data.length == 2">
                             <div class="compare-item position-relative">
                                {{ $t('add_to_compare') }} </div>
                        </div>
                        <div class="col-md-3">
                            <div class="compare-button">
                                <button class="btn btn-compare-offers" :disabled="isDisable" @click.prevent="compareDatas">{{ $t('compare_offer') }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from '@/store';
import Qoutes from '@/core/services/car/Qoutes';
import router from '@/router';
import { useReCaptcha } from 'vue-recaptcha-v3'

    const props = defineProps({
        data: {
            required: true,
            type: Array
        },
        isOpen: {
            required: true,
            type: Boolean
        }
    })

        const store = useStore();
        const captcha = useReCaptcha()

        const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
        const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)

        const compareData = computed(() => {
            return store.state.qoutes.compareData
        })

        const removeCompare = (i) => {
            props.data.splice(i, 1)
            // Qoutes.getCompareData(data)
        }

        const isDisable = ref(false);

        // handle click event
        const compareDatas = async () => {
            // Qoutes.getCompareData(props.data.map(({ id }) => id))
            // load captcha
            isDisable.value = true;
            await captcha?.recaptchaLoaded()
            // execute reCaptcha
            const token = await captcha?.executeRecaptcha('login');
            console.log(token);
            if(token) {
                Qoutes.getCompareData(props.data)
                // router.push({name: 'CompareOffer', query: { uuid: router.currentRoute.value.query.uuid }})
                const fullPath = router.currentRoute.value.fullPath
                const path = fullPath.split('?')
                window.location.href = `/compare-offers?${path[1]}`
                isDisable.value = false;
            }
        }
        
</script>
